import React, { useState } from "react"
import styled from "styled-components"

const LoginPageStyled = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: #0B2D71;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  input {
    margin-bottom: 1rem;
  }

  div {
    width: 90%;
    max-width: 400px;
  }

  .error {
    color: red;
    margin-bottom: 1rem;
  }

  button {
    background: #FFCC00;
    border: 0;
    padding: .5rem 1rem;
    font-weight: bold;
  }
`

export default function LoginPage({handleLogin}) {
  const [password, setPassword] = useState(null)
  const [attempt, setAttempt] = useState(null)

  const handleChange = (e) => {
    setPassword(e.target.value)
    
  }

  const attemptLogin = () => {
    setAttempt(true)
    handleLogin(password)
  }

  return(
    <LoginPageStyled>
      <div>
        <h1>Password</h1>
        <p>Please enter the password to view this page.</p>
        <input 
          type="password" 
          name="password" 
          onChange={(e) => handleChange(e) } 
          className="form-control" 
        />
        {attempt && (
          <p class="error">Incorrect password, please try again.</p>
        )}
        <button onClick={attemptLogin}>
          Login
        </button>
      </div>
    </LoginPageStyled>
  )
}